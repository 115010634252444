<template>
  <section>
    <tab-navbar class="mb-4" :options="tabs"></tab-navbar>
    <b-card class="dashboard-card shadow-sm mr-t-4">
      <router-view @handleSave="changeTab" />
    </b-card>
  </section>
</template>
<script>
import TabNavbar from "../../../components/Common/TabNavbar.vue";

export default {
  props: ["companyID"],
  data() {
    return {
      tabs: [
        { text: "Company Information", to: "CompanyInfo" },
        { text: "Address", to: "CompanyAddress" },
        { text: "Phone", to: "CompanyPhone" },
      ],
    };
  },
  components: {
    "tab-navbar": TabNavbar,
  },
  methods: {
    changeTab(route) {
      var currentTabIndex = this.tabs.findIndex((t) => t.to === route);
      if (currentTabIndex !== this.tabs.length - 1) {
        this.$router.push({
          name: this.tabs[currentTabIndex + 1].to,
          params: { companyID: this.companyID },
        });
      }
    },
  },
};
</script>
